import React, { MouseEventHandler } from "react";
import classes from "./callback-form.module.scss";
import { Controller, useForm } from "react-hook-form";
import { PrimaryButton } from "@components/UI/Buttons/PrimaryButton/PrimaryButton";
import { isValidEmail } from "@src/utils/utils";
import PhoneInput from "react-phone-input-2";
import TextInput from "@components/UI/Inputs/TextInput/TextInput";
import TextAreaInput from "@shared/ui/inputs/text-area-input/text-area-input";
import classNames from "classnames";
import { useSendCallbackMutation } from "@app/store/callbackFormApi";
import { toast } from "react-toastify";
import { FetchBaseQueryError } from "@reduxjs/toolkit/dist/query/react";
import { hideModals } from "@app/store/layoutSlice";
import { useDispatch } from "react-redux";
import { CallbackFormProps } from "./callback-form-props";
import { CloseIcon } from "@shared/ui/Icon/ui/Common/CloseIcon";
import {
    emailValidationRules,
    messageValidationRules,
    nameValidationRules,
    phoneValidationRules
} from "./callback-form.validations";

const CallbackForm: React.FC<CallbackFormProps> = ({ onClose, ...props }) => {
    const {
        handleSubmit,
        formState: { errors },
        control
    } = useForm();

    const dispatch = useDispatch();
    const [sendCallbackData, response] = useSendCallbackMutation();

    const textInputStyle = {
        paddingLeft: 10
    };

    const buttonStyle = {
        marginTop: 22,
        marginBottom: 18,
        height: 56,
        fontSize: 18
    };

    const onSubmit = async (e: React.MouseEvent | React.KeyboardEvent<HTMLDivElement>) => {
        e.preventDefault();
        const submit = handleSubmit(async (data) => {
            const result = await sendCallbackData({
                name: data.Name,
                email: data.Email,
                phone: data.Phone,
                message: data.Message
            });

            if (result && "error" in result) {
                const error = result.error as FetchBaseQueryError;
                toast.error(`Ошибка на сервере: ${error.status},  попробуйте позже`, {
                    autoClose: 5000
                });
            } else {
                toast.success("Ваш запрос успешно отправлен", {
                    autoClose: 5000
                });
            }

            dispatch(hideModals());
        });
        submit();
    };

    const showError = (errName: string) => {
        const errMsg = errors[errName]?.message;
        return <span className={classes.Error}>{errMsg && errMsg.toString()}</span>;
    };

    return (
        <div
            onKeyDown={(event) => {
                if (event.key === "Enter") {
                    onSubmit(event);
                }
            }}
            className={classes.CallbackFrom}
        >
            <div onClick={onClose} className={classes.CloseBtn}>
                <CloseIcon />
            </div>

            <h3>Вход или регистрация</h3>

            <form>
                <div className={classNames(classes.InputCont, classes.InputContRequired)}>
                    <label>Электронная почта</label>
                    <Controller
                        control={control}
                        name="Email"
                        rules={emailValidationRules}
                        render={({ field }) => {
                            return (
                                <TextInput
                                    value={field.value}
                                    onChange={(val) => {
                                        if (val) {
                                            field.onChange(val.toString().replace(/\s/g, ""));
                                        } else {
                                            field.onChange("");
                                        }
                                    }}
                                    style={textInputStyle}
                                    placeholder="Введите электронную почту"
                                />
                            );
                        }}
                    />
                    {errors.EnterEmail && (
                        <span className={classes.Error}>
                            {errors?.Email?.message && errors?.Email?.message.toString()}
                        </span>
                    )}
                </div>

                <div className={classNames(classes.InputCont, classes.InputContRequired)}>
                    <label>Имя</label>
                    <Controller
                        control={control}
                        name="Name"
                        rules={nameValidationRules}
                        render={({ field }) => {
                            return (
                                <TextInput
                                    value={field.value}
                                    onChange={field.onChange}
                                    style={textInputStyle}
                                    placeholder="Введите имя"
                                />
                            );
                        }}
                    />
                    {errors.Name && showError("Name")}
                </div>

                <div className={classes.InputCont}>
                    <label>Телефон</label>
                    <Controller
                        control={control}
                        name="Phone"
                        rules={phoneValidationRules}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <PhoneInput
                                placeholder="+7 (999) 123 45 67"
                                country={"ru"}
                                inputProps={{
                                    autoFocus: false,
                                    required: true
                                }}
                                value={value}
                                onChange={(phone) => onChange(phone)}
                            />
                        )}
                    />
                    {errors.Phone && showError("Phone")}
                </div>

                <div className={classes.InputCont}>
                    <label>Сообщение</label>
                    <Controller
                        control={control}
                        name="Message"
                        rules={messageValidationRules}
                        render={({ field: { onChange, onBlur, value, ref } }) => (
                            <TextAreaInput
                                placeholder="введите ваш вопрос"
                                value={value}
                                onChange={(phone) => onChange(phone)}
                            />
                        )}
                    />
                    {errors.Message && showError("Message")}

                    <PrimaryButton
                        isLoading={false}
                        onClick={onSubmit}
                        style={buttonStyle}
                        content="Отправить"
                    />
                </div>
            </form>
        </div>
    );
};

export default CallbackForm;